// import React from 'react';
import { GithubOutlined } from '@ant-design/icons';
import DefaultFooter from '@ant-design/pro-layout/lib/Footer';
import { Footer } from 'antd/lib/layout/layout';
import { Link } from 'umi';

const footerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#8D8F90',
};

export default () => (
  <Footer style={footerStyle}>
    <a href="http://www.x-one.pl">X-ONE</a> | Na licencji MIT: © 2022 Escola Soft <GithubOutlined/>
  </Footer>
);
