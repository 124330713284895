export default {
  'menu.welcome': 'Witaj',
  'menu.more-blocks': 'Więcej bloków',
  'menu.home': 'Home',
  'menu.admin': 'Admin',
  'menu.admin.sub-page': 'Podstrona',
  'menu.login': 'Zaloguj',
  'menu.register': 'Zarejestruj',
  'menu.register.result': 'Podsumowanie rejestracji',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analiza',
  'menu.dashboard.monitor': 'Monitoruj',
  'menu.dashboard.workplace': 'Miejsce pracy',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Form',
  'menu.form.basic-form': 'Podstawowy formularz',
  'menu.form.step-form': 'Formularz krok po kroku',
  'menu.form.step-form.info': 'Formularz krok po kroku(wpisz informacje o przelewie)',
  'menu.form.step-form.confirm': 'Formularz krok po kroku(potwierdź informacje o przelewie)',
  'menu.form.step-form.result': 'Formularz krok po kroku(zakończono)',
  'menu.form.advanced-form': 'Zaawansowany formularz',
  'menu.list': 'Lista',
  'menu.list.table-list': 'Wyszukaj',
  'menu.list.basic-list': 'Podstawowa lista',
  'menu.list.card-list': 'Lista kart',
  'menu.list.search-list': 'Lista wyszukiwań',
  'menu.list.search-list.articles': 'Lista wyszukiwań(artykuły)',
  'menu.list.search-list.projects': 'Lista wyszukiwań(projekty)',
  'menu.list.search-list.applications': 'Lista wyszukiwań(aplikacje)',
  'menu.profile': 'Profil',
  'menu.profile.basic': 'Podstawowy profil',
  'menu.profile.advanced': 'Zaawansowany profil',
  'menu.result': 'Wynik',
  'menu.result.success': 'Sukces',
  'menu.result.fail': 'Coś poszło nie tak',
  'menu.exception': 'Wyjątek',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Konto',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Opcje konta',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Wyloguj',
  'menu.editor': 'Edytor graficzny',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
};
