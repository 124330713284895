export default {
  vouchers: 'Vouchery',
  voucher: 'Voucher',
  'vouchers.code': 'Kod vouchera',
  'vouchers.limit_usage': 'Limit użycia',
  'vouchers.max_cart_price': 'Max. wartość koszyka (netto)',
  'vouchers.min_cart_price': 'Min. wartość koszyka (netto)',
  'vouchers.type': 'Typ vochera',
  'vouchers.included_products': 'Uwzględnione produkty',
  'vouchers.excluded_products': 'Wykluczone produkty',
  'vouchers.cart_fixed': 'Koszyk stały',
  'vouchers.cart_percent': 'Koszyk procentowy',
  'vouchers.product_fixed': 'Produkt ustalony',
  'vouchers.product_percent': 'Produkt procentowy',
  'voucher.edit': 'Edytuj voucher',
};
