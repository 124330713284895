import component from './pl-PL/component';
import globalHeader from './pl-PL/globalHeader';
import menu from './pl-PL/menu';
import pwa from './pl-PL/pwa';
import settingDrawer from './pl-PL/settingDrawer';
import settings from './pl-PL/settings';
import pages from './pl-PL/pages';
import notifications from './pl-PL/notifications';
import templates from './pl-PL/templates';
import consultations from './pl-PL/consultations';
import fabric from './pl-PL/fabric';
import vouchers from './pl-PL/vouchers';

export default {
  file_resources: 'Załączniki (pliki)',
  select_user_role: 'Wybierz rolę',
  select_user_group: 'Wybierz Grupę',
  groups: 'Grupy',
  'navBar.lang': 'Języki',
  'layout.user.link.help': 'Pomoc',
  'layout.user.link.privacy': 'Prywatność',
  'layout.user.link.terms': 'Warunki',
  'app.preview.down.block': 'Pobierz tę stronę do swojego lokalnego projektu',
  'app.welcome.link.fetch-blocks': 'Weź wszystkie bloki',
  'app.welcome.link.block-list': 'Szybko buduj standardowe strony w oparciu o `block` development ',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
  ...notifications,
  ...templates,
  ...consultations,
  ...fabric,
  ...vouchers,
  branding: 'Branding',
  product_details: 'Ustawienia produktu',
  title: 'Tytuł',
  options: 'Opcje',
  base_price: 'Cena podstawowa [gr]',
  course_base_price: 'Cena podstawowa z produktu [zł]',
  base_price_tooltip: 'Cena podstawowa [gr]. Wpisz 0 dla darmowego kursu',
  duration: 'Czas trwania',
  categories: 'Kategorie',
  tags: 'Tagi',
  H5P: 'H5P',
  H5Ps: 'H5P',
  new: 'Nowy',
  library: 'Biblioteka',
  'menu.Courses': 'Kursy',
  'menu.Users': 'Użytkownicy',
  'menu.Users.List': 'Lista',
  'menu.Courses.List': 'Lista',
  'menu.Courses.Form': 'Formularz',
  'menu.Users.Roles': 'Role',
  'menu.Sales': 'Sprzedaż',
  'menu.Users.User': 'Użytkownik',
  'menu.Users.User Form': 'Użytkownicy',
  'menu.Welcome': 'Witaj',
  'menu.Courses.Course Form': 'Formularz kursu',
  'menu.Users.Form': 'Formularz',
  'menu.Courses.H5Ps': 'H5P',
  'menu.Courses.Categories': 'Kategorie',
  'menu.Configuration': 'Konfiguracja',
  'menu.Other activities': 'Inne aktywności',
  'menu.Configuration.Files': 'Pliki',
  'menu.Other activities.Pages': 'Strony',
  'menu.Sales.Payments': 'Płatności',
  'menu.Sales.Orders': 'Zamówienia',
  'menu.Sales.Products': 'Produkty',
  'menu.Courses.SCORMs': 'SCORMy',
  'menu.Analytics': 'Analityka',
  'menu.Analytics.Notifications': 'Powiadomienia',
  'menu.settings': 'Ustawienia',
  'menu.Settings': 'Ustawienia',
  'menu.Configuration.settings': 'Opcje',
  'menu.Analytics.reports': 'Raporty',
  'menu.Courses.Webinars': 'Webinary',
  'menu.Users.User Groups': 'Grupy',
  'menu.Configuration.Templates': 'Szablony',
  'menu.Configuration.Translations': 'Tłumaczenia',
  'menu.Other activities.Questionnaire': 'Ankiety',
  'menu.Other activities.Consultations': 'Konsultacje',
  'menu.Analytics.Logs': 'Logi',
  user_settings: 'Ustawienia użytkownika',
  global_settings: 'Ustawienia globalne',
  'user.settings': 'Ustawienia użytkownika',
  'user.settings.overwrite': 'Nadpisz wszystko',
  'menu.Sales.Vouchers': 'Vouchery',
  'menu.Other activities.StationaryEvents': 'Wydarzenia Stacjonarne',
  'menu.Other activities.Questionnaire Form': 'Ankiety',
  'stationary_event.edit': 'Formularz Wydarzenia Stacjonarne',
  stationary_event: 'Wydarzenie Stacjonarne',
  'menu.reset': 'reset',
  finished_at: 'Data zakończenia',
  max_participants: 'Max ilość uczestników',
  place: 'Miejsce',
  started_at: 'Data rozpoczęcia',
  id: 'id',
  'menu.Users.Permissions': 'Uprawnienia',
  StationaryEvents: 'Wydarzenia Stacjonarne',
  settings: 'Settings',
  package: 'Pakiet',
  readonly: 'Tylko do odczytu',
  access: 'Dostęp',
  user_groups: 'Grupy',
  user_group: 'Grupa',
  new_user_group: 'Nowa Grupa',
  user_group_id: 'ID Grupy: ',
  edit_user_group: 'Edycja Grupy',
  add_person_to_group: 'Wybierz osobę z listy aby dodać ją do grupy',
  Active: 'Aktywny',
  active: 'Aktywny',
  answers: 'Odpowiedzi',
  Inactive: 'Nieaktywny',
  inactive: 'Nieaktywny',
  address: 'Adres',
  draft: 'Roboczy',
  published: 'Opublikowany',
  assign: 'Przypisz',
  archived: 'Zarchiwizowany',
  new_template: 'Nowy szablon',
  edit_template: 'Edycja Szablonu',
  preview_pdf: 'Podgląd PDF',
  PDF: 'PDF',
  Push: 'Wyślij',
  SMS: 'SMS',
  vars_set: 'Typ zmiennych',
  vars_set_tooltip: 'Typ zmiennych to np Certyfikaty, email o nowym koncie, itp',
  template: 'Szablon',
  templates: 'Szablony',
  full_name: 'Pełna nazwa',
  slug: 'Slug',
  name: 'Nazwa',
  ID: 'ID',
  newCategory: 'Utwórz nową kategorię',
  editCategory: 'Edytuj kategorię',
  parent_category: 'Nadrzędna kategoria',
  is_active: 'Aktywny?',
  parent_id: 'Nadrzędna kategoria',
  parent_id_group: 'Nadrzędna grupa',
  parent_id_group_tooltip: 'Szukaj po id',
  registerable: 'Pokaż w rejestracji',
  import_file: 'Importuj plik',
  edit: 'Edytuj',
  delete: 'Usuń',
  deleteQuestion: 'Czy na pewno chcesz usunąć ten rekord?',
  payments: 'Płatności',
  order: 'Zamówienie',
  orders: 'Zamówienia',
  amount: 'Ilość',
  status: 'Status',
  users: 'Użytkownicy',
  user: 'Użytkownik',
  first_name: 'Imię',
  last_name: 'Nazwisko',
  email: 'Email',
  email_resend: 'Email został wysłany ponownie',
  event: 'Wydarzenie',
  general: 'Ogólne',
  password: 'Hasło',
  current_password: 'Aktualne hasło',
  new_password: 'Nowe hasło',
  new_confirm_password: 'Potwierdź nowe hasło',
  note: 'Notatka',
  to: 'do',
  notifications: 'Powiadomienia',
  change_password: 'Zmień hasło',
  search: 'Wyszukaj',
  search_tooltip_1: 'Szukanie odbywa się po imieniu, nazwisku oraz emailu',
  search_tooltip_user_groups: 'Szukanie odbywa się po nazwie',
  roles: 'Role',
  rodo: 'Polityka prywatności',
  regulations: 'Regulamin serwisu',
  permissions: 'Uprawnienia',
  verified: 'Zweryfikowany',
  not_verified: 'Niezweryfikowany',
  is_email_verified: 'Email zweryfikowany?',
  email_verified: 'Email zweryfikowany',
  dateRange: 'Zakres dat',
  created_at: 'Utworzono',
  create_new: 'Utwórz nowy',
  count_h5p: 'Ilość h5p',
  library_id: 'ID biblioteki',
  updated_at: 'Zaktualizowano',
  unsaved_changes: 'Wprowadzone zmiany mogą nie zostać zapisane.',
  currency: 'Waluta',
  subtotal: 'Suma częściowa',
  tax: 'Podatek',
  total: 'Suma',
  items: 'Pozycje',
  author: 'Autor',
  course: 'Kurs',
  select: 'Proszę wybrać',
  select_tags: 'Wybierz tagi',
  select_person: 'Wybierz osobę',
  select_course: 'Wybierz kurs',
  select_templates: 'Wybierz szablony',
  select_type_topic: 'Wybierz rodzaj tematu żeby kontynuować',
  select_scorm_package: 'Wybierz pakiet SCORM',
  select_certificate_package: 'Wybierz szablon certyfikatu',
  billable: 'Rachunek do',
  payable: 'Płatne do',
  newH5P: 'Nowy plik H5P',
  H5P_scorm_preview: 'H5P. Podgląd treści SCORM',
  H5P_uploaded: 'Nowy plik H5P dodano prawidłowo',
  H5P_preview_title: 'H5P. Podgląd treści HTML',
  H5P_new_content_title: 'H5P. Nowa treść HTML5',
  H5P_edit_content_title: 'H5p. Edycja',

  H5P_select_content: 'Wybierz treść H5P',
  upload_click_here: 'Kliknij tutaj żeby dodać',
  upload: 'Dodaj plik',
  preview: 'Podgląd',
  export: 'Eksport',
  able_to_preview: 'Możliwość podglądu',
  loading_content: 'Ładowanie treści...',
  preview_content: 'Podgląd treści',
  version: 'Wersja',
  SCOS: 'Udostępniane treści obiektów',
  newScorm: 'Nowy SCORM',
  scorm_uploaded: 'Dodano nowy SCORM',
  yes: 'Tak',
  no: 'Nie',
  cantDelete: 'Nie możesz usunąć tej kategorii, ponieważ jest ona nadrzędna dla innych',
  group: 'Grupa',
  key: 'Klucz',
  type: 'Typ',
  enumerable: 'Przeliczalny',
  public: 'Publiczny',
  value: 'Wartość',
  editSetting: 'Edytuj',
  newSetting: 'Utwórz',
  success: 'Operacja zakończona pomyślnie',
  error: 'Wystąpił błąd',
  loading: 'Ładowanie',
  subtitle: 'Podtytuł',
  language: 'Język',
  level: 'Poziom',
  active_from: 'Aktywny od',
  active_to: 'Aktywny do',
  hours_to_complete: 'Łączna długość',
  purchasable: 'Do kupienia',
  findable: 'Możliwy do wyszukania',
  target_group: 'Grupa docelowa',
  author_tutor: 'Autor / Nauczyciel',
  short_description: 'Krótki opis',
  summary: 'Podsumowanie',
  summary_tooltip:'Opis wyświetlany pod głównym contentem tematu',
  description: 'Opis',
  description_tooltip:"Opis wyświetlany pod wprowadzeniem i nad głównym contentem tematu",
  content: 'Treść',
  contact: 'Kontakt',
  country: 'Kraj',
  slug_tooltip: 'SLUG to część adresu URL pod jakim dostępna będzie strona',
  content_tooltip:
    'Dany edytor WYSIWYG zawiera narzędzia do formatowania, zachowując jednocześnie możliwość pisania Markdown z klawiatury oraz wyświetlania zwykłego Markdown.',
  attributes: 'Atrybuty',
  new_course: 'Nowy kurs',
  new_questionnaire: 'Nowa Ankieta',
  questionnaires: 'Ankiety',
  questionnaire: 'Ankieta',
  'questionnaire.submit': 'Zatwierdź',
  'questionnaire.raports.filter': 'Filtruj po przypisanych modelach',
  questions: 'Pytania',
  question_list: 'Lista pytań',
  question_add: 'Dodaj pytanie',
  question_edit: 'Edytuj pytanie',
  media: 'Media',
  image: 'Zdjęcie',
  import_users: 'Import użytkowników',
  poster: 'Plakat',
  position: 'Pozycja',
  video: 'Wideo',
  audio: 'Dźwięk',
  categories_tags: 'Kategorie i tagi',
  program: 'Program',
  scorm: 'SCORM',
  relase_drop: 'Zwolnij, aby upuścić',
  sum_rate: 'Ranking',
  count_answers: 'Ilość odpowiedzi',
  drag_here: 'Przeciągnij tutaj',
  add_new_lesson: 'Dodaj nową lekcję',
  lesson: 'Lekcja',
  topic: 'Temat',
  topics: 'Tematy',
  topic_list: 'Lista Tematów',
  topic_types: 'Typy Tematów',
  tutor: 'Trener',
  tutors: 'Trenerzy',
  pages: 'Strony',
  new_page: 'Nowa strona tekstowa',
  edit_page: 'Edycja strony tekstowej',
  add_new_topic: 'Dodaj nowy temat',
  no_topics: 'Brak tematów w tej lekcji',
  no_data: 'Brak danych',
  create: 'Utwórz',
  questions_coach:'Pytania & odpowiedzi',
  save: 'Zapisz',
  sort_element_up: 'Sortuj ten element w górę',
  sort_element_down: 'Sortuj ten element w dół',
  copy_lesson: 'Kopiuj Lekcje',
  copy_topic: 'Kopiuj Temat',
  'h5p.description.header': 'Informacje dot. H5P',
  'h5p.description.first': 'Aby przypisać interaktywny element HTML5 do tematu lekcji, musisz',
  'h5p.description.second': ' najpierw go utworzyć ',
  'h5p.description.third': 'lub użyć któregoś',
  'h5p.description.fourth': ' z listy',
  'h5p.description.fifth': 'jeśli już jest stworzony. Naciśnij przycisk ',
  'h5p.description.sixth': 'aby zobaczyć jak wygląda treść i zobaczyć listę zdarzeń `XAPI`.',
  my_profile: 'Mój profil',
  logout: 'Wyloguj',
  true: 'Prawda',
  false: 'Fałsz',
  bio: 'Biogram',
  bio_tooltip:
    'Dany edytor WYSIWYG zawiera narzędzia do formatowania, zachowując jednocześnie możliwość pisania Markdown z klawiatury oraz wyświetlania zwykłego Markdown.',
  avatar: 'Awatar',
  avatar_placeholder: 'Dodaj awatar żeby zobaczyć podgląd',
  wysiwyg_placeholder:
    'Zacznij wpisywać tekst, aby zobaczyć funkcje edytora. Zacznij od „/”, aby otworzyć opcje formatowania lub kliknij przycisk „+”',
  edit_user: 'Edycja użytkownika',
  new_user: 'Nowy użytkownik',
  send: 'Wyślij',
  resend: 'Wyślij jeszcze raz',
  back: 'Wróć',
  back_home: 'Wróć do strony głównej',
  '403_subtitle': 'Nie masz dostępu do tej strony.',
  '404_subtitle': 'Przepraszamy, ta strona nie istnieje.',
  user_id: 'ID Użytkownika: ',
  order_id: 'ID Zamówienia: ',
  webinar_id: 'ID Webinaru: ',
  stationary_event_id: 'ID Wydarzenia Stacjonarnego: ',
  course_id: 'ID Kursu: ',
  consultation_id: 'ID Konsultacji: ',
  new_payment: 'nowe',
  paid: 'opłacone',
  cancelled: 'anulowane',
  cancel: 'Anuluj',
  failed: 'nie powiodło się',
  clear_selection: 'wyczyść',
  form: 'Formularz',
  none: 'Brak',
  too_many_chars: 'Za dużo znaków',
  CoursesMoneySpentMetric: 'Wskaźnik wydatków na kursy',
  CoursesPopularityMetric: 'Wskaźnik popularności kursów',
  CoursesSecondsSpentMetric: 'Wskaźnik spędzonego czasu na kursach',
  TutorsPopularityMetric: 'Wskaźnik popularności nauczycieli',
  AverageTimePerTopic: 'Średni czas spędzony na temacie',
  MoneyEarned: 'Zarobione pieniądze',
  CertificateTemplates: 'Szablony certyfikatów',
  AverageTime: 'Całkowity spędzony czas na tematach',
  People: 'Ludzie',
  PeopleBought: 'Ilość osób, która kupiła kurs',
  PeopleFinished: 'Ilość osób, która skończyła kurs',
  PeopleStarted: 'Ilość osób, która zaczęła kurs',
  reuse_existing: 'Użyj istniejącego',
  open_new_content_editor: 'Otwórz nowy edytor treści',
  example_json: 'Przykładowy JSON',
  can_skip: 'Możliwość pominięcia',
  statistics: 'Statystyki',
  certificates: 'Certyfikaty',
  query: 'Szukaj',
  reset: 'Wyczyść',
  reset_password: 'Resetuj hasło',
  search_file: 'Wyszukaj plik',
  product: 'Produkt',
  'product.edit': 'Edycja Produktu',
  'menu.Other activities.Products': 'Produkty',
  json: 'JSON Metadata',
  additional: 'Dodatkowe',
  json_tooltip:
    'To pole słuzy programistom aby dodać dodatkowe dane do Tematu, do obsługi przy wyświetlaniu kursu',
  open_editor: 'Otwórz edytor',
  json_editor: 'Otwórz edytor JSON',
  introduction: 'Wprowadzenie',
  introduction_tooltip:'Opis wyświetlany nad głownym contentem tematu',
  'webinar.edit': 'Edycja Webinaru',
  role: 'Rola',
  'course.validate_edit.header': 'Ten kurs już trwa! Czy na pewno chcesz go edytować?',
  'course.validate_edit.content':
    'Po kliknięciu przycisku "OK" będziesz miał/miała możliwość edycji. Robisz to na własną odpowiedzialność. Pamiętaj, żeby działać ostrożnie i uważać na wprowadzane zmiany.',
  limit_total: 'Limit ogólny',
  limit_per_user: 'Limit per uzytkownik',
  extra_fees: 'Extra opłaty',
  price_old: 'Cena sprzed promocji (przekreslenie)',
  price_old_tooltip:"Cena sprzed promocji ( na stronie będzie przekreślona) w groszach",
  price_old_format:"Stara cena (sformatowana w zł)",
  tax_value: 'Wartość podatku VAT',
  price_brutto: 'Cena brutto',
  tax_rate: 'Podatek vat (%)',
  price: 'Cena',
  price_format:'Cena sformatowana netto',
  price_format_tooltip:'Cena sformatowana netto ( wartość w zł)',
  price_format_brutto:'Cena sformatowana brutto',
  teaser_url: 'Teaser URL',
  select_product: 'Wybierz produkt',
  productables: 'Obiekt przypisany do produktu',
  bundle: 'Ścieżka',
  single: 'Pojedyńczy',
  area: 'Obszar',
  StationaryEvent: 'Wydarzenie Stacjonarne',
  Course: 'Kurs',
  courses: 'Kursy',
  Consultation: 'Konsultacja',
  default: 'Domyślna Wartość',
  text: 'text',
  varchar: 'varchar',
  number: 'number',
  boolean: 'boolean',
  editModelField: 'Edycja Dodatkowych Attrybutów',
  ModelFields: 'Dodatkowe Atrybuty',
  list: 'Lista',
  newModelField: 'Nowy Dodatkowy Attrybut',
  notPowerOfTwo: 'To nie jest potęga 2',
  rules: 'Zasady walidacji',
  visibility: 'Widoczność pola',
  name_tooltip: 'nazwa',
  type_tooltip: 'typ',
  rules_tooltip: 'a json list of string rules used on create/update model',
  extra_tooltip: 'a json extra description',
  default_tooltip: 'jezeli model nie ma zapisanej wartość pojawi się wartość domyślna ',
  visibility_tooltip:
    'Widoczność pola w API. Musi być potęgą 2. Use  1 to Public access, 2 for Authorized, 4 for Admin, and rest is up to you',
  'available-validation-rules': 'dostępne metody walidacji',
  Products: 'Produkty',
  free: 'darmowy',
  free_capi: 'Darmowy',
  prices: 'Ceny',
  additional_fields: 'Dodatkowe pola',
  purchasable_tooltip: 'Mozliwość zakupu, albo tylko ręczne przypisanie',
  user_to_add: 'Przypisz użytkownika do produktu',
  user_submission: 'Zapisanie użytkownika bez konta',
  email_to_add: 'Dodaj użytkownika po adresie email',
  Webinar: 'Webinar',
  productable: 'Encja',
  all: 'Wszystkie',
  categories_and_tags: 'Kategorie i Tagi',
  course_edit_warning_message:
    'Ten kurs jest już opublikowany, jeśli chcesz edytować kliknij zatwierdź. UWAŻAJ! Edytujesz na własną odpowiedzialność',
  user_logs: 'Logi użytkownika',
  select_content: 'Wybierz kontent',
  exclude_promotions: 'Czy łączy sie z innymi promocjami',

  generate: 'Generuj',
  generate_pdf: 'Generuj PDF(y)',
  generate_pdf_tooltip: 'Generuj PDF(y)',
  generate_pdf_users_tooltip:
    'Klinij aby wygenerować PDFy dla tego szablonu dla wszystkich uzytkowników z listy',
  generate_pdf_products_tooltip:
    'Klinij aby wygenerować PDFy dla tego szablonu dla wszystkich uzytkowników którzy zakupili wybrany produkt',
  generate_pdf_for_products_tooltip:
    'Klinij aby wygenerować PDFy dla wybranego szablonu dla wszystkich uzytkowników którzy zakupili ten produkt',
  select_template: 'Select template',
  product_categories_and_tags: 'Kategorie i Tagi produktu',
  cart_media: 'Media do koszyka',
  users_attached: 'Dołączenie użytkownika',
  generate_token: 'Generuj token dla youtube',
  browse: 'Przeglądaj pliki',
  related_products: 'Powiązane produkty',
  selected_products: 'Wybrane obiekty',
  quantity: 'Ilość',
  gt_last_login_day: '>= Logowanie z ostatnich n dni',
  lt_last_login_day: '<= Logowanie z ostatnich n dni',
  created_before: 'Utworzony przed',
  created_after: 'Utworzony po',
  redirect: 'przekierowanie',
  logotype: 'Logotyp',
  published_unactivated: 'Opublikowany nieaktywny',
  active_from_extra: 'Wybierz datę rozpoczęcia sprzedaży',
  active_to_extra: 'Wybierz datę zakończenia sprzedaży',
  duration_extra: 'Określ czas trwania sprzedaży w minutach',
  hours_to_complete_extra: 'Łączna długość trwania kursu wyświetlana na stronie kursu [h]',
  course_success_modal_title: 'Gratulacje!',
  course_success_modal_content: 'Utworzyłeś kurs. Teraz możesz tworzyć lekcje dla swojego kursu.',
  course_success_modal_button_ok: 'Stwórz moduły',
  course_success_modal_button_cancel: 'Idź do strony kursu',
  program_form_empty_list_message:
    'Nie masz wybranej żadnej treści. Stwórz pierwszą lekcję żeby zacząć.',
  program_form_not_selected_list_message: 'Wybierz lekcję albo moduł do edytowania.',
  clone: 'Klonuj',
  drag_topic_tooltip: 'Przeciągnij aby zmienić kolejność materiału',
  invalidDate: 'Błędna data',
  badPassword: 'min. 8 znaków, 1 duża litera, 1 znak specjalny (!@#$%^&*))',
  product_widget_title: 'Atrybuty produktu',
  product_widget_tooltip: 'Wszystkie poniższe atrybuty są związane tylko z produktem',
  course_guide_title: 'Nie wiesz jak tworzyć kurs?',
  course_guide_text: 'Sprawdź nasz poradnik i dowiedz się jak tworzyć kurs.',
  global_settings_alert_title: 'Dodaj ustawienia globalne',
  global_settings_alert_description:
    'Globalne ustawienia są udostępniane dla całego LMS. Możesz dodać globalne ustawienia klikając przycisk poniżej.',
  go_to_platform: 'Przejdź do Twojej platformy',
  manage_course: 'Zarządzaj kursem',
  tutorial: 'Poradnik',
  tutorial_card_left_title: 'Jesteś gotowy aby współdzielić swoje wiedzę.',
  tutorial_card_left_description:
    'Użyj naszych wskazówek i poradników aby zaplanować, stworzyć i opublikować wysokiej jakości kurs.',
  tutorial_card_right_title: 'Nie wiesz jak tworzyć kurs?',
  tutorial_card_right_description: 'Sprawdź nasz poradnik i dowiedz się jak stworzyć kurs.',
  learn_more: 'Dowiedz się więcej',
  your_courses: 'Twoje kursy',
  unverified: 'Niezweryfikowany',
  download: 'Pobierz',
  newTranslation: 'Nowe tłumaczenie',
  documentation: 'Dokumentacja',
  ratings: 'Oceny',
  ratings_description: 'Zmierz oceny dla Twoich kursów',
  'edugate.settings': 'Ustawienia Edugate',
  show_in_menu: 'Pokaż w menu ?',
  category_order: 'Kolejność',
  category_color: 'Kolor',
  area: 'Obszar',
  bundle_type: 'Rodzaj pakietu',
  sections: 'Sekcje',
  Sections: 'Sekcje',
  products: 'produkty',
  'menu.Sales.Sections': 'Sekcje',
  'menu.Courses.TagDictionaries': 'Tagi',
  'menu.Configuration.Dictionaries': 'Słowniki',
  'menu.Configuration.Agreements': 'Zgody',
  'menu.Other activities.Opinions': 'Opinie',
  'menu.Other activities.CoachRequests': 'Zostań trenerem',
  'menu.Courses.Exams': 'Egzaminy',
  about_product: 'Czego się nauczysz?',
  about_product_gift_card:"Korzyści",
  add: 'Dodaj',
  order_by: 'Kolejność',
  photo: 'Zdjęcie',
  photo_description: 'Opis zdjęcia',
  actions: 'Akcje',
  link: 'Link',
  product_timeline: 'Oś czasu',
  category: 'Kategoria',
  field_required: 'Pole jest wymagane',
  company: 'Firma',
  paths: 'Ścieżki',
  areas: 'Obszary',
  products_in_area: 'Produkty obszaru',
  required: 'Wymagany',
  Agreements: 'Zgody',
  price_price_old_validation: 'Stara cena  nie może być niższa od nowej ceny brutto',
  comment: 'Komentarz',
  rating: 'Ocena',
  exams: 'Egzaminy',
  product_type: 'Typ produktu',
  file_max_size_exceded: 'Przekrocozno maksymalny rozmiar pliku',
  included_categories: 'Uwzględnione kategorie',
  excluded_categories: 'Wykluczone kategorie',
  gift_card: 'Karta podarunkowa',
  meta_title: 'Meta tytuł',
  meta_description: 'Meta opis',
  meta_tags: 'Meta tagi',
  for_order: 'Dla zamówienia',
  exam_type: 'Egzamin dla',
  amount_product_percent: 'Procent',
  amount_product_fixed: 'Kwota (netto)',
  amount_cart_percent: 'Procent',
  amount_cart_fixed: 'Kwota (netto)',
  http_method: 'Metoda HTTP',
  path: 'Ścieżka',
  generated_pdfs: 'Wygenerowane PDFy',
  download_all: 'Pobierz wszystkie',
  download_pdf: 'Pobierz PDF',
  title_section: 'Tytuł sekcji',
  description_section: 'Opis sekcji',
  title_exam: 'Tytuł egzaminu',
  tasks_count: 'Ilość zadań',
  time: 'Czas',
  product_percent_validation: 'Prosze wybrac uwzględnione produkty lub kategorie',
  'App\\Events\\PathFinished' : 'Produkt ukończony',
  'EscolaLms\\AssignWithoutAccount\\Events\\AssignToProduct' : 'Przypisanie do produktu',
  'EscolaLms\\AssignWithoutAccount\\Events\\AssignToProductable' : 'Przypisanie do produktu',
  'EscolaLms\\Auth\\Events\\AccountBlocked' : 'Konto zablokowane',
  'EscolaLms\\Auth\\Events\\AccountConfirmed' : 'Konto potwierdzone',
  'EscolaLms\\Auth\\Events\\AccountDeleted' : 'Konto usunięte',
  'EscolaLms\\Auth\\Events\\AccountMustBeEnableByAdmin' : 'Konto musi być włączone przed administratora',
  'EscolaLms\\Auth\\Events\\AccountRegistered' : 'Konto stworzone',
  'EscolaLms\\Auth\\Events\\ForgotPassword' : 'Hasło zapomniane',
  'EscolaLms\\Auth\\Events\\PasswordChanged' : 'Hasło zmienione',
  'EscolaLms\\Auth\\Events\\UserAddedToGroup' : 'Użytkownik dodany do grupy',
  'EscolaLms\\Auth\\Events\\UserRemovedFromGroup' : 'Użytkownik usunięty z grupy',
  'EscolaLms\\Cart\\Events\\OrderCreated' : 'Zamówienie utworzone',
  'EscolaLms\\Cart\\Events\\ProductAttached' : 'Produkt załączony',
  'EscolaLms\\Consultations\\Events\\ApprovedTerm' : 'Zaakceptowany regulamin',
  'EscolaLms\\Consultations\\Events\\ApprovedTermWithTrainer' : 'Zaakceptowany regulamin z trenerem',
  'EscolaLms\\Consultations\\Events\\RejectTerm' : 'Odrzucenie regulaminu',
  'EscolaLms\\Consultations\\Events\\RejectTermWithTrainer' : 'Odrzucenie regulaminu z trenerem',
  'EscolaLms\\Consultations\\Events\\ReminderAboutTerm' : 'Przypomnienie o regulaminie',
  'EscolaLms\\Consultations\\Events\\ReminderTrainerAboutTerm' : 'Przypomnienie o regulaminie trenerowi',
  'EscolaLms\\Consultations\\Events\\ReportTerm' : 'Zgłoś regulamin',
  'EscolaLms\\Courses\\Events\\CourseAccessFinished' : 'Dostęp do kursu',
  'EscolaLms\\Courses\\Events\\CourseAccessStarted' : 'Dostęp do kursu start',
  'EscolaLms\\Courses\\Events\\CourseAssigned' : 'Kurs przypisywany',
  'EscolaLms\\Courses\\Events\\CourseDeadlineSoon' : 'Deadline kursu się zbliża',
  'EscolaLms\\Courses\\Events\\CourseFinished' : 'Kurs skończony',
  'EscolaLms\\Courses\\Events\\CourseStarted' : 'Kurs rozpoczęty',
  'EscolaLms\\Courses\\Events\\CourseUnassigned' : 'Dostęp do kursu usunięty',
  'EscolaLms\\Courses\\Events\\CoursedPublished' : 'Kurs opublikowany',
  'EscolaLms\\Courses\\Events\\TopicFinished' : 'Lekcja ukończona',
  'EscolaLms\\CsvUsers\\Events\\EscolaLmsImportedNewUserTemplateEvent' : 'Zaimportowany użytkownik',
  'EscolaLms\\Templates\\Events\\ManuallyTriggeredEvent' : 'Ręczne wydarzenie',
  'EscolaLms\\Webinar\\Events\\ReminderAboutTerm' : 'Przypomnienie o regulaminie',
  'EscolaLms\\Webinar\\Events\\WebinarTrainerAssigned' : 'Przypisany trener do webinaru',
  'EscolaLms\\Webinar\\Events\\WebinarTrainerUnassigned' : 'Usunięty trener z webinaru',
  'EscolaLms\\Youtube\\Events\\YtProblem' : 'Problem YT',
  'amount_undefined': 'Ilość',
  'h5p_edited': 'Zapisano pomyślnie',
  'header': 'Nagłówek',
  copy_cta: 'Kopiuj przycisk',
  delete_cta:"Usuń przycisk",
  add_new_cta:"Dodaj nowy przycisk CTA na stronie",
  price_tooltip:"Aktualna cena w  podana w groszach ( po prawej znajduję się sformatowana kwota)",
  price_brutto_tooltip:"Cena sformatowana brutto. Ta cena zostanie wyświetlona przy produktcie",
  tax_rate_tooltip:"Globalny podatek vat, możesz go skonfigurować przechodząc do Konfiguracja -> Opcje ->  Ustawienia użytkownika i edycja klucza tax_rate [%]",
  product_name_tooltip:"Nazwa produktu wyświetlana na stronie produktu",
  tax_value_tooltip:"Sformatowany Podatek VAT ",
  duration_tooltip:"Czas trwania szkolenia [h]",
  product_description_tooltip:"Opis produktu wyświetlany na stronie produktu",
  productables_tooltip:"Kurs przypisany do pojedynczego produktu",
  giftcard_description_tooltip:"Ten opis będzie wyświetlany na kafelku produktu karty podarunkowej np.: (200zł)",
  product_progress_image_tooltip:"Zdjęcie wyświetlane w postępach użytownika w kafelce produktu",
  product_poster_tooltip:"Zdjęcie wyświetlana na kafelku produktu (np. na liście kategorii)",
  product_video_tooltip:"Wideo wyświetlane  na stronie produktu",
  course_title_tooltip:"Tytuł kursu wyświetlany w kafelku produktu grupowego typu Obszar i scieżka oraz na stronie z lekcjami",
  level_tooltip:"Poziom ( możesz go zdefiniować w Konfiguracja -> Słowniki -> tworząc nowy z typem [difficulty_level] ",
  status_tooltip:"Tylko kurs ze statusem OPUBLIKOWANY będzie wyświetlany, nawet gdy jest już przypisany do produktu",
  hours_to_complete_tooltip: 'Łączna długość trwania kursu wyświetlana na stronie kursu [h]',
  course_poster_tooltip:"Zdjęcie kursu wświetlane w kafelce na stronie produktu grupowego typu ścieżka/obszar",
  lesson_title_tooltip:"Temat lekcji (pole tekstowe)",
  lesson_duration_tooltip:"Czas trwania lekcji [min] (na front'cie dodawana jest jednostka `min.`)",
  topic_topic_tooltip:"Tytuł rozdziału w lekcji (pole tekstowe)",
  go_to_product:"Kliknij aby przejść do  połączonego produktu",
  promotion_to: "Promocja do"
};
