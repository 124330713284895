// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/srv/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-layout__Layout' */'/srv/src/.umi/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "login",
                "path": "/user/login",
                "access": "loggedOut",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__login' */'/srv/src/pages/User/login'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "reset",
                "path": "/user/reset-password",
                "access": "loggedOut",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__reset' */'/srv/src/pages/User/reset'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/welcome",
        "name": "Welcome",
        "icon": "smile",
        "access": "dashboardPermission",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/srv/src/pages/Welcome'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/courses",
        "name": "Courses",
        "icon": "fire",
        "access": "coursesPermission",
        "routes": [
          {
            "path": "/courses",
            "redirect": "/courses/list",
            "access": "coursesPermission",
            "exact": true
          },
          {
            "path": "/courses/list",
            "name": "List",
            "icon": "fire",
            "access": "courseListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Courses' */'/srv/src/pages/Courses'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/courses/list/:course",
            "name": "Form",
            "icon": "book",
            "access": "courseDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Courses__form' */'/srv/src/pages/Courses/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/courses/list/:course/:tab",
            "name": "Course Form",
            "icon": "book",
            "access": "courseDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Courses__form' */'/srv/src/pages/Courses/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": false
          },
          {
            "path": "/courses/h5ps",
            "name": "H5Ps",
            "icon": "experiment",
            "access": "h5pListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__H5P' */'/srv/src/pages/H5P'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/courses/h5ps/:h5p",
            "name": "H5Ps",
            "icon": "book",
            "access": "h5pDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__H5P__form' */'/srv/src/pages/H5P/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/courses/h5ps/preview/:h5p",
            "name": "H5Ps",
            "icon": "book",
            "access": "h5pDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__H5P__preview' */'/srv/src/pages/H5P/preview'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/courses/categories",
            "name": "Categories",
            "icon": "calculator",
            "access": "categoryListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Categories' */'/srv/src/pages/Categories'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/courses/tag_dictionaries",
            "name": "TagDictionaries",
            "icon": "tag",
            "access": "categoryListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TagDictionaries' */'/srv/src/pages/TagDictionaries'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/courses/tag_dictionaries/new",
            "name": "TagDictionaries",
            "icon": "tag",
            "access": "categoryListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TagDictionaries__form' */'/srv/src/pages/TagDictionaries/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/courses/tag_dictionaries/:id",
            "name": "TagDictionaries",
            "icon": "tag",
            "access": "categoryListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TagDictionaries__form' */'/srv/src/pages/TagDictionaries/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/courses/exams",
            "name": "Exams",
            "icon": "exam",
            "access": "categoryListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exams' */'/srv/src/pages/Exams'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/courses/exams/new",
            "name": "Exams",
            "icon": "tag",
            "access": "categoryListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exams__form' */'/srv/src/pages/Exams/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/courses/exams/:id",
            "name": "Exams",
            "icon": "tag",
            "access": "categoryListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exams__form' */'/srv/src/pages/Exams/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/sales",
        "name": "Sales",
        "access": "salesPermission",
        "icon": "shopping-cart",
        "routes": [
          {
            "path": "/sales",
            "redirect": "/sales/orders",
            "access": "salesPermission",
            "exact": true
          },
          {
            "path": "/sales/orders",
            "name": "Orders",
            "icon": "fund",
            "access": "orderListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Orders' */'/srv/src/pages/Orders'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sales/payments",
            "name": "Payments",
            "icon": "dollar",
            "access": "paymentListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Payments' */'/srv/src/pages/Payments'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sales/vouchers",
            "name": "Vouchers",
            "icon": "percentage",
            "access": "voucherListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Vouchers' */'/srv/src/pages/Vouchers'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sales/vouchers/:voucherId/",
            "access": "voucherDetailPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Vouchers__form' */'/srv/src/pages/Vouchers/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/sales/products",
            "name": "Products",
            "icon": "shopping-cart",
            "access": "productsListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Products' */'/srv/src/pages/Products'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sales/products/:id",
            "access": "productsDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Products__form' */'/srv/src/pages/Products/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/sales/products/:id/:tab",
            "access": "productsDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Products__form' */'/srv/src/pages/Products/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/sales/sections",
            "name": "Sections",
            "icon": "shopping-cart",
            "access": "productsDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Sections' */'/srv/src/pages/Sections'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sales/sections/new",
            "name": "Sections",
            "icon": "shopping-cart",
            "access": "productsDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Sections__form' */'/srv/src/pages/Sections/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/sales/sections/:id",
            "name": "Sections",
            "icon": "shopping-cart",
            "access": "productsDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Sections__form' */'/srv/src/pages/Sections/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/users",
        "name": "Users",
        "access": "usersPermission",
        "icon": "user",
        "routes": [
          {
            "path": "/users",
            "redirect": "/users/list",
            "access": "usersPermission",
            "exact": true
          },
          {
            "path": "/users/list",
            "name": "List",
            "icon": "user",
            "access": "userListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Users' */'/srv/src/pages/Users'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/users/list/new",
            "icon": "user",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Users__User' */'/srv/src/pages/Users/User'), loading: LoadingComponent}),
            "access": "userCreatePermission",
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "Roles",
            "path": "/users/roles",
            "icon": "lock",
            "access": "roleListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Roles' */'/srv/src/pages/Roles'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Permissions",
            "path": "/users/roles/:name",
            "icon": "lock",
            "access": "roleDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Roles__form' */'/srv/src/pages/Roles/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/users/groups",
            "name": "User Groups",
            "icon": "team",
            "access": "userGroupListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserGroups' */'/srv/src/pages/UserGroups'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/users/groups/:group",
            "name": "Form",
            "icon": "team",
            "access": "userGroupDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserGroups__form' */'/srv/src/pages/UserGroups/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/users/me",
            "name": "User",
            "icon": "user",
            "access": "userProfilePermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Users__me' */'/srv/src/pages/Users/me'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/users/me/:tab",
            "name": "User Form",
            "icon": "user",
            "access": "userProfilePermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Users__me' */'/srv/src/pages/Users/me'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/users/:user/:tab",
            "icon": "user",
            "access": "userDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Users__User' */'/srv/src/pages/Users/User'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/users/fields",
            "icon": "user",
            "access": "userDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Users__fields' */'/srv/src/pages/Users/fields'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "name": "Analytics",
        "path": "/analytics",
        "access": "analyticsPermission",
        "icon": "rise",
        "routes": [
          {
            "path": "/analytics",
            "redirect": "/analytics/notifications",
            "access": "analyticsPermission",
            "exact": true
          },
          {
            "name": "Notifications",
            "icon": "alert",
            "path": "/analytics/notifications",
            "access": "notificationListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Notifications' */'/srv/src/pages/Notifications'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "reports",
            "icon": "project",
            "path": "/analytics/reports",
            "access": "reportListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Reports' */'/srv/src/pages/Reports'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Logs",
            "icon": "history",
            "path": "/analytics/logs",
            "access": "trackerListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Logs' */'/srv/src/pages/Logs'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "Configuration",
        "path": "/configuration",
        "access": "configurationPermission",
        "icon": "setting",
        "routes": [
          {
            "path": "/configuration",
            "redirect": "/configuration/files",
            "access": "configurationPermission",
            "exact": true
          },
          {
            "path": "/configuration/files",
            "name": "Files",
            "icon": "folderOpen",
            "access": "fileListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Files' */'/srv/src/pages/Files'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/configuration/settings",
            "name": "settings",
            "icon": "calculator",
            "access": "settingListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Settings' */'/srv/src/pages/Settings'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/configuration/settings/:tab",
            "name": "settings",
            "icon": "calculator",
            "access": "settingListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Settings' */'/srv/src/pages/Settings'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/configuration/templates",
            "name": "Templates",
            "icon": "highlight",
            "access": "templateDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Templates' */'/srv/src/pages/Templates'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/configuration/templates/:template",
            "name": "Templates",
            "icon": "highlight",
            "access": "templateDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Templates' */'/srv/src/pages/Templates'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/configuration/templates/:template/:id",
            "name": "Templates",
            "icon": "highlight",
            "access": "templateDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Templates__form' */'/srv/src/pages/Templates/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "Translations",
            "icon": "translation",
            "path": "/configuration/translations",
            "access": "translationListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Translations' */'/srv/src/pages/Translations'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Dictionaries",
            "icon": "dictionary",
            "path": "/configuration/dictionaries",
            "access": "translationListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dictionaries' */'/srv/src/pages/Dictionaries'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/configuration/dictionaries/new",
            "name": "Dictionaries",
            "icon": "dictionary",
            "access": "translationListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dictionaries__form' */'/srv/src/pages/Dictionaries/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/configuration/dictionaries/:id",
            "name": "Dictionaries",
            "icon": "dictionary",
            "access": "translationListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dictionaries__form' */'/srv/src/pages/Dictionaries/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "Banner",
            "icon": "banner",
            "path": "/configuration/banner",
            "access": "translationListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Banner' */'/srv/src/pages/Banner'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/configuration/banner/new/:category",
            "name": "Banner",
            "icon": "banner",
            "access": "translationListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Banner__form' */'/srv/src/pages/Banner/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/configuration/banner/:id",
            "name": "Banner",
            "icon": "banner",
            "access": "translationListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Banner__form' */'/srv/src/pages/Banner/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "Agreements",
            "icon": "agreements",
            "path": "/configuration/agreements",
            "access": "translationListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Agreements' */'/srv/src/pages/Agreements'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/configuration/agreements/new",
            "name": "Agreements",
            "icon": "agreements",
            "access": "translationListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Agreements__form' */'/srv/src/pages/Agreements/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/configuration/agreements/:id",
            "name": "Agreements",
            "icon": "agreements",
            "access": "translationListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Agreements__form' */'/srv/src/pages/Agreements/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "name": "Other activities",
        "path": "/other",
        "access": "otherPermission",
        "icon": "appstore",
        "routes": [
          {
            "path": "/other/pages",
            "name": "Pages",
            "icon": "read",
            "access": "pageListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Pages' */'/srv/src/pages/Pages'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/other/pages/:page",
            "name": "Form",
            "icon": "read",
            "access": "pageDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Pages__form' */'/srv/src/pages/Pages/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/other/pages/new",
            "name": "Form",
            "icon": "read",
            "access": "pageDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Pages__form' */'/srv/src/pages/Pages/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/other/opinions",
            "name": "Opinions",
            "icon": "read",
            "access": "questionnaireListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Opinions' */'/srv/src/pages/Opinions'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/other/coach_requests",
            "name": "CoachRequests",
            "icon": "read",
            "access": "coachRequestListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CoachRequests' */'/srv/src/pages/CoachRequests'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "dashboardPermission",
        "path": "/",
        "redirect": "/welcome",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/srv/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
