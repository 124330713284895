import component from './en-US/component';
import globalHeader from './en-US/globalHeader';
import menu from './en-US/menu';
import pwa from './en-US/pwa';
import settingDrawer from './en-US/settingDrawer';
import settings from './en-US/settings';
import pages from './en-US/pages';
import notifications from './en-US/notifications';
import templates from './en-US/templates';
import consultations from './en-US/consultations';
import fabric from './en-US/fabric';
import vouchers from './en-US/vouchers';

export default {
  file_resources: 'File resources',
  select_user_role: 'Select role',
  select_user_group: 'Select User Group',
  groups: 'Groups',
  'navBar.lang': 'Languages',
  'layout.user.link.help': 'Help',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'app.preview.down.block': 'Download this page to your local project',
  'app.welcome.link.fetch-blocks': 'Get all block',
  'app.welcome.link.block-list': 'Quickly build standard, pages based on `block` development',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
  ...notifications,
  ...templates,
  ...consultations,
  ...fabric,
  ...vouchers,

  branding: 'Branding',
  product_details: 'Product details',
  title: 'Title',
  options: 'Options',
  base_price: 'Base price [¢]',
  base_price_tooltip: 'Base price [¢]. Use 0 for free course',
  duration: 'Duration',
  categories: 'Categories',
  tags: 'Tags',
  H5Ps: 'H5Ps',
  new: 'new',
  library: 'Library',
  access: 'Access',
  answers: 'Answers',
  'menu.Courses': 'Courses',
  'menu.Courses.List': 'List',
  'menu.Courses.Form': 'Form',
  'menu.Sales': 'Sales',
  'menu.Users': 'Users',
  'menu.Users.List': 'List',
  'menu.Users.User': 'User',
  'menu.Users.User Form': 'Users',
  'menu.Welcome': 'Welcome',
  'menu.Courses.Course Form': 'Course Form',
  'menu.Users.Form': 'Form',
  'menu.Courses.H5Ps': 'H5Ps',
  'menu.Courses.Categories': 'Categories',
  'menu.Configuration': 'Configuration',
  'menu.Configuration.Files': 'Files',
  'menu.Configuration.Translations': 'Translations',
  'menu.Other activities.Pages': 'Pages',
  'menu.Sales.Payments': 'Payments',
  'menu.Sales.Orders': 'Orders',
  'menu.Sales.Products': 'Products',
  'menu.Sales.Sections': 'Sections',
  'menu.Courses.SCORMs': 'SCORMs',
  'menu.Configuration.settings': 'Settings',
  'menu.Analytics.reports': 'Reports',
  'menu.Courses.Webinars': 'Webinars',
  'menu.Users.User Groups': 'User Groups',
  'menu.Other activities.Questionnaire': 'Questionnaires',
  'menu.Other activities.Questionnaire Form': 'Questionnaire Form',
  'menu.Other activities': 'Other activities',
  'menu.Other activities.Consultations': 'Consultations',
  'menu.Other activities.StationaryEvents': 'Stationary Events',
  'menu.Analytics.Logs': 'Logs',
  'menu.Sales.Vouchers': 'Vouchers',
  'stationary_event.edit': 'Stationary Event Edit Form',
  stationary_event: 'Stationary Event',
  finished_at: 'Finished at Date',
  started_at: 'Started at Date',
  max_participants: 'Max number of participants',
  StationaryEvents: 'Stationary Events',
  'menu.reset': 'reset',
  'menu.settings': 'Settings',
  'menu.Settings': 'Settings',
  id: 'id',
  place: 'Place',
  user_groups: 'User Groups',
  new_user_group: 'New User Group',
  edit_user_group: 'Edit User Group',
  add_person_to_group: 'Add person to group',
  user_settings: 'User settings',
  global_settings: 'Global settings',
  'user.settings': 'User settings',
  'user.settings.overwrite': 'Overwrite all',
  Active: 'Active',
  address: 'Address',
  Inactive: 'Inactive',
  active: 'Active',
  inactive: 'Inactive',
  draft: 'Draft',
  published: 'Published',
  archived: 'Archived',
  slug: 'Slug',
  name: 'Name',
  ID: 'ID',
  newCategory: 'Create new Category',
  editCategory: 'Edit Category',
  parent_category: 'Parent category',
  is_active: 'Active?',
  parent_id: 'Parent category',
  parent_id_group: 'Parent group',
  parent_id_group_tooltip: 'Search by id',
  registerable: 'Registerable',
  edit: 'Edit',
  delete: 'Delete',
  deleteQuestion: 'Are you sure to delete this record?',
  payments: 'Payments',
  order: 'Order',
  orders: 'Orders',
  amount: 'Amount',
  status: 'Status',
  users: 'Users',
  user: 'User',
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  email_resend: 'Email resent',
  event: 'Event',
  general: 'General',
  password: 'Password',
  current_password: 'Actual password',
  new_password: 'New password',
  new_confirm_password: 'Confirm new password',
  notifications: 'Notifications',
  change_password: 'Change password',
  search: 'Search',
  search_tooltip_1: 'Will search through first name, last name and email',
  search_tooltip_user_groups: 'Will search through name',
  roles: 'Roles',
  rodo: 'Privacy policy',
  regulations: 'Terms of service',
  permissions: 'Permissions',
  verified: 'Verified',
  not_verified: 'Unverified',
  is_email_verified: 'Email verified?',
  email_verified: 'Email verified',
  dateRange: 'Date range',
  created_at: 'Created at',
  create_new: 'Create new',
  updated_at: 'Updated at',
  unsaved_changes: 'Changes you made may not be saved.',
  currency: 'Currency',
  subtotal: 'Subtotal',
  tax: 'Tax',
  total: 'Total',
  items: 'Items',
  author: 'Author',
  course: 'Course',
  select: 'Please select',
  select_tags: 'Select tags',
  to: 'to',
  select_person: 'Select a person',
  select_course: 'Select a course',
  select_templates: 'Select a templates',
  select_type_topic: 'Select type of topic to continue',
  select_scorm_package: 'Select SCORM package',
  select_certificate_package: 'Select certificate template',
  billable: 'Billable to',
  payable: 'Payable to',
  newH5P: 'New file H5P',
  H5P_scorm_preview: 'H5P. Scorm content preview',
  H5P_uploaded: 'New file H5P uploaded successfully',
  H5P_preview_title: 'H5P. HTML Content preview',
  H5P_new_content_title: 'H5p. New',
  H5P_edit_content_title: 'H5p. Edit',

  H5P_select_content: 'Select content for H5P',
  upload_click_here: 'Click here to add file',
  upload: 'Add file',
  preview: 'Preview',
  export: 'Export',
  able_to_preview: 'Able to preview',
  loading_content: 'Loading content...',
  preview_content: 'Preview content',
  version: 'Version',
  SCOS: 'Sharable Content Objects',
  assign: 'Assign',
  newScorm: 'New SCORM',
  scorm_uploaded: 'New SCORM uploaded',
  yes: 'Yes',
  no: 'No',
  cantDelete: "You can't delete this category because it's parent to others",
  group: 'Group',
  key: 'Key',
  type: 'Type',
  enumerable: 'Enumerable',
  note: 'Note',
  public: 'Public',
  value: 'Value',
  editSetting: 'Edit',
  newSetting: 'Create',
  success: 'Success',
  error: 'Error',
  loading: 'Loading',
  subtitle: 'Subtitle',
  language: 'Language',
  level: 'Level',
  active_from: 'Active from',
  active_to: 'Active to',
  hours_to_complete: 'Hours to complete',
  purchasable: 'Purchasable',
  findable: 'Findable',
  target_group: 'Target group',
  author_tutor: 'Author / Tutor',
  summary: 'Summary',
  summary_tooltip:
    'The editor is WYSIWYG and includes formatting tools whilst retaining the ability to write markdown shortcuts inline and output plain Markdown.',
  short_description: 'Short description',
  description: 'Description',
  description_tooltip:
    'The editor is WYSIWYG and includes formatting tools whilst retaining the ability to write markdown shortcuts inline and output plain Markdown.',
  attributes: 'Attributes',
  new_course: 'New course',
  new_questionnaire: 'New Questionnaire',
  questionnaires: 'Questionnaires',
  questionnaire: 'Questionnaire',
  'questionnaire.submit': 'Submit',
  'questionnaire.raports.filter': 'Filter by assigned models',
  questions: 'Questions',
  question_list: 'Questions list',
  question_add: 'Add question',
  question_edit: 'Edit question',
  media: 'Media',
  image: 'Image',
  poster: 'Poster',
  video: 'Video',
  audio: 'Audio',
  categories_tags: 'Categories & Tags',
  program: 'Program',
  position: 'Position',
  scorm: 'SCORM',
  add_new_lesson: 'Add new lesson',
  lesson: 'Lesson',
  topic: 'Topic',
  topics: 'Topics',
  topic_list: 'Topic List',
  topic_types: 'Topic types',
  tutor: 'Tutor',
  tutors: 'Tutors',
  add_new_topic: 'Add new topic',
  no_topics: 'There are no topics yet on this lesson',
  no_data: 'No data',
  create: 'Create',
  save: 'Save',
  relase_drop: 'Release to drop',
  drag_here: 'Drag a box here',
  sort_element_up: 'Sort this element up',
  sort_element_down: 'Sort this element down',
  copy_lesson: 'Copy Lesson',
  copy_topic: 'Copy Topic',
  'h5p.description.header': 'H5P Info',
  'h5p.description.first': 'To assign Interactive HTML5 element to lesson topic you need to ',
  'h5p.description.second': 'create it first',
  'h5p.description.third': ' or use one ',
  'h5p.description.fourth': 'from the list',
  'h5p.description.fifth': " if it's already created. Press",
  'h5p.description.sixth':
    'button to see how does content looks like and see list of `XAPI` events.',
  my_profile: 'My profile',
  logout: 'Logout',
  bio: 'Bio',
  bio_tooltip:
    'The editor is WYSIWYG and includes formatting tools whilst retaining the ability to write markdown shortcuts inline and output plain Markdown.',
  avatar: 'avatar',
  avatar_placeholder: 'Add avatar to see preview',
  wysiwyg_placeholder:
    "Type here text to see the rich text editor features. Start with '/' to open formatting options or click on '+' button",
  edit_user: 'Edit user',
  new_user: 'New user',
  send: 'Send',
  resend: 'Resend',
  back: 'Back',
  back_home: 'Back to main page',
  '403_subtitle': 'Sorry, you are not authorized to access this page.',
  '404_subtitle': 'Sorry, the page you visited does not exist.',
  user_id: 'User ID: ',
  order_id: 'Order ID: ',
  course_id: 'Course ID: ',
  consultation_id: 'Consultation ID: ',
  webinar_id: 'Webinar ID: ',
  stationary_event_id: 'Stationary Event ID: ',
  count_h5p: 'H5P count',
  library_id: 'Library ID',
  new_payment: 'new',
  paid: 'paid',
  cancelled: 'cancelled',
  cancel: 'Cancel',
  failed: 'failed',
  clear_selection: 'clear selection',
  form: 'Form',
  none: 'None',
  too_many_chars: 'Too many characters',
  CoursesMoneySpentMetric: 'Courses money spent metric',
  CoursesPopularityMetric: 'Courses popularity metric',
  CoursesSecondsSpentMetric: 'Courses seconds spent metric',
  TutorsPopularityMetric: 'Tutors popularity metric',
  AverageTimePerTopic: 'Average time per topic',
  MoneyEarned: 'Money earned',
  CertificateTemplates: 'Certificate templates',
  AverageTime: 'Total time',
  People: 'People',
  PeopleBought: 'People who bought this course',
  PeopleFinished: 'People who completed this course',
  PeopleStarted: 'People who started this course',
  reuse_existing: 'Reuse existing',
  open_new_content_editor: 'Open new content editor',
  content: 'Content',
  contact: 'Contact',
  country: 'Country',
  slug_tooltip: 'SLUG is part of Pages URL ',
  sum_rate: 'Ranking',
  count_answers: 'Answers count',
  content_tooltip:
    'The editor is WYSIWYG and includes formatting tools whilst retaining the ability to write markdown shortcuts inline and output plain Markdown.',
  pages: 'Pages',
  import_users: 'Import users',
  import_file: 'Import file',
  new_page: 'New static page',
  edit_page: 'Edit static page',
  example_json: 'JSON Example',
  can_skip: 'Can be omitted',
  statistics: 'Statistics',
  certificates: 'Certificates',
  query: 'Query',
  reset: 'Reset',
  reset_password: 'Reset password',
  search_file: 'Search file',
  open_editor: 'Open editor',
  json: 'JSON Metadata',
  additional: 'Additional',
  json_tooltip: 'Additional metadata for rendering course',
  introduction: 'Introduction',
  introduction_tooltip:
    'The editor is WYSIWYG and includes formatting tools whilst retaining the ability to write markdown shortcuts inline and output plain Markdown.',
  Push: 'Push',
  templates: 'Templates',
  SMS: 'SMS',
  PDF: 'PDF',
  'menu.Analytics': 'Analytics',
  'menu.Analytics.Notifications': 'Notifications',
  'menu.Users.Roles': 'Roles',
  'menu.Configuration.Templates': 'Templates',
  'menu.Users.Permissions': 'Permissions',
  new_template: 'New Template',
  template: 'Template',
  contentHtml: 'HTML Content',
  contentMjml: 'Mjml Content',
  html_tooltip: 'HTML content',
  product: 'Product',
  'product.edit': 'Edit Product',
  'menu.Other activities.Products': 'Products',
  H5P: 'H5P',
  'webinar.edit': 'Webinar Edit',
  role: 'Role',
  'course.validate_edit.header':
    'This course is already underway! Are you sure you want to edit it?',
  'course.validate_edit.content':
    'You should not edit this course as it is already in progress. Clicking ok will allow you to edit, but you do so at your own risk.',
  Course: 'Course',
  Consultation: 'Consultation',
  limit_total: 'Limit total',
  limit_per_user: 'Limit per user',
  extra_fees: 'Extra fees',
  price_old: 'Price old (strikethrough)',
  tax_value: 'Tax value',
  price_brutto: 'Price brutto',
  tax_rate: 'Tax rate',
  price: 'Price',
  teaser_url: 'Teaser URL',
  select_product: 'Select product',
  productables: 'Object assign to product',
  bundle: 'Bundle',
  single: 'Single',
  StationaryEvent: 'Stationary Event',
  default: 'Default Value',
  text: 'text',
  varchar: 'varchar',
  number: 'number',
  boolean: 'boolean',
  editModelField: 'Edit Model Field',
  ModelFields: 'Model Fields',
  list: 'List',
  newModelField: 'New Model Field',
  notPowerOfTwo: 'This is not a power 2',
  rules: 'Rules',
  visibility: 'Visibility',
  name_tooltip: 'name of field',
  type_tooltip: 'type of field',
  rules_tooltip: 'a json list of string rules used on create/update model',
  extra_tooltip: 'a json extra description',
  default_tooltip: 'default value, if model has no value this will be served',
  purchasable_tooltip: 'Can user buy this product or is available only by manual assignment',
  visibility_tooltip:
    'visibility of field. Must be power of 2. Use  1 to Public access, 2 for Authorized, 4 for Admin, and rest is up to you',
  'available-validation-rules': 'available validation rules',
  Products: 'Products',
  free: 'free',
  free_capi: 'Free',
  prices: 'Prices',
  additional_fields: 'Additional Fields',
  user_to_add: 'Attach user to product',
  user_submission: 'Users Attached without Account',
  email_to_add: 'Add user by email address',
  Webinar: 'Webinar',
  productable: 'Entity',
  all: 'All',
  categories_and_tags: 'Categories & Tags',
  course_edit_warning_message:
    'This record is already in progress, if you wish to edit click confirm. You edit at your own risk',
  user_logs: 'User logs',
  http_method: 'HTTP method',
  path: 'Path',
  generated_pdfs: 'Generated PDFs',
  download_all: 'Download all',
  download_pdf: 'Download PDF',
  select_content: 'Select content',
  exclude_promotions: 'Can it be combined with other promotions',
  generate: 'Generate',
  generate_pdf: 'Generate PDF(s)',
  generate_pdf_tooltip: 'Generate PDF(s)',
  generate_pdf_users_tooltip:
    'Click this button to generate PDF for this template for all user from the list',
  generate_pdf_products_tooltip:
    'Click this button to generate PDF for this template for all user that purchased selected Product',
  generate_pdf_for_products_tooltip:
    'Click this button to generate PDF for selected template for all users that purchased this Product',
  select_template: 'Wybierz szablon',
  product_categories_and_tags: 'Product Categories & Tags',
  cart_media: 'Cart Media',
  users_attached: 'Users Attached',
  generate_token: 'Generate youtube token',
  browse: 'Browse files',
  related_products: 'Related Products',
  selected_products: 'Selected objects',
  quantity: 'Quantity',
  gt_last_login_day: '>= Login last n days',
  lt_last_login_day: '<= Login last n days',
  json_editor: 'Open JSON editor',
  created_before: 'Created before',
  created_after: 'Created after',
  redirect: 'redirect',
  logotype: 'Logotype',
  published_unactivated: 'Opublikowany nieaktywny',
  active_from_extra: 'Select a sales activation date',
  active_to_extra: 'Select a sales deactivation date',
  duration_extra: 'Specify duration in minutes',
  hours_to_complete_extra: 'Specify duration in hours',
  course_success_modal_title: 'Congratulations!',
  course_success_modal_content:
    'You have created a course. Now you can create lessons for your course.',
  course_success_modal_button_ok: 'Create modules',
  course_success_modal_button_cancel: 'Go to course page',
  program_form_empty_list_message:
    'You have no content to view yet. Create your first lesson to get started.',
  program_form_not_selected_list_message: 'Select a lesson or topic to edit.',
  clone: 'Clone',
  drag_topic_tooltip: 'Click to edit or drag & drop to reorder topic.',
  invalidDate: 'Invalid date',
  badPassword: 'min. 8 characters, 1 capital letter, 1 special character (!@#$%^&*))',
  product_widget_title: 'Product Attibutes',
  product_widget_tooltip: 'All attributes below are related only to product',
  course_guide_title: 'Not sure how to create a course?',
  course_guide_text: 'Check out our guide and learn how to do it.',
  global_settings_alert_title: 'Add global settings',
  global_settings_alert_description:
    'Global settings are used to share data in entire LMS. You can add global settings by clicking the button below.',
  go_to_platform: 'Go to the your platform',
  manage_course: 'Manage course',
  tutorial: 'Tutorial',
  tutorial_card_left_title: 'You’re ready to share your knowledge.',
  tutorial_card_left_description:
    'Use our best practices and recommendations to plan, produce, and publish a substantive, high-quality course.',
  tutorial_card_right_title: 'Not sure how to create a course?',
  tutorial_card_right_description: 'Check out our guide and learn how to do it.',
  learn_more: 'Learn more',
  your_courses: 'Your courses',
  ratings: 'Ratings',
  ratings_description: 'Measure the rating given to your courses',
  unverified: 'Unverified',
  download: 'Download',
  newTranslation: 'New Translation',
  documentation: 'Documentation',
  'edugate.settings': 'Edugate settings',
  show_in_menu: 'Show in menu ?',
  category_order: 'Order',
  category_color: 'Color',
  area: 'Area',
  bundle_type: 'Bundle type',
  sections: 'Sections',
  copy_cta: 'Copy CTA',
  delete_cta: 'Delete CTA',
  add_new_cta: 'Add new CTA',
};
